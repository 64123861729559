.return-container {
    background: linear-gradient(white, #FFE0B2 40%, #FFCCBC 60%, white 100%);
    height: 100vh; 
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .return-content {
    background-color: #FFE0B2;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    width: 90%;
  }
  
  .return-content h2 {
    margin: 0 0 10px;
    color: #333;
  }
  
  .return-content p {
    margin: 0;
    color: #555;
  }
  

  