.auth{
    width: 90%;
    max-width: 400px;
    border: 1px solid #dcdcdc;
    padding: 20px;
    text-align: center;
  }
  
  .auth form{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto 10px auto;
  }
  
  .auth__success{
    padding: 10px 0;
    background-color: hsl(147, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth__error{
    padding: 10px 0;
    background-color: hsl(0, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth form > input, button{
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
  }

  .font-color {
    color: #ff5126;
    font-size: 70px;
}

.register-account {
  font-size: 15px;
  margin-top: 22px;
}

.register-button {
  padding: 10px;
  background-color: #ff5126;
  /* Bootstrap primary color */
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}

@media(max-width: 768px) {

  .register-account {
    font-size: 15px;
    margin-top: 22px;
  }

  .register-button {
    padding: 10px;
    background-color: #ff5126;
    /* Bootstrap primary color */
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
 
}