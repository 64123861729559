.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(10px);
}

.loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading-logo {
    width: 150px;
    margin-bottom: 20px;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #FE6D40;
    border-right: 8px solid #FFA724;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
    margin-bottom: 20px;
}

.loading-text {
    font-size: 1.2em;
    color: #333;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
